import React from 'react'
import Plans from '../../components/plans/Plans'
import './premium.scss'

const Premium = () => {

  return (
    <div className='premium'>
      <div className="container">
        {/* <Plans /> */}
        <div className="banner">Cumming soon 💦💦</div>
      </div>
    </div>
  )
}

export default Premium